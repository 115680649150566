<template>
  <div class="page-wrapper">
    
    <header class="main-header always-sticky">
      <div class="left">
        <img src="@/assets/img/logo.svg" class="logo" alt="Logo Tremblant">
        <img src="@/assets/img/tagline-fr.svg" v-if="$route.meta.lang === 'fr'" class="tagline" alt="Tagline Tremblant">
        <img src="@/assets/img/tagline-en.svg" v-else-if="$route.meta.lang === 'en'" class="tagline" alt="Tagline Tremblant">
      </div>
      <div class="right">
        <div class="links">
          <a href="tel:18887381777">1-888-738-1777</a>
          <p>|</p>
          <router-link v-if="pageData && $route && $route.meta.lang === 'fr'" :to="{ name: 'pageEn', params: { pageData } }" class="lang">EN</router-link>
          <router-link v-else-if="pageData && $route && $route.meta.lang === 'en'" :to="{ name: 'page', params: { pageData } }" class="lang">FR</router-link>
        </div>
        <a href="#" class="cta red" @click.prevent="showlodgingform">{{ $t('site.reservez-long') }}</a>
      </div>
      <a href="tel:18887381777" class="phone-mobile"><img src="@/assets/img/phone.svg" alt="Call Tremblant"></a>
      <div class="mobile-cta">
        <a href="#" class="cta red" @click.prevent="showlodgingform">{{ $t('site.reservez-long') }}</a>
      </div>
    </header>

    <section class="terms-wrap ete_2022">

      <div v-if="$route.meta.lang === 'fr'">

        <button @click.prevent="goback" class="cta prune">Retour</button><br><br>

        <h1 class="big-title">Modalités<br>hébergement</h1>

        <h2 class="small-title">Validité</h2>
        <p class="regular-text">L’offre est valable tous les jours du 2 août au 31 octobre 2022, à l’exception du 18 au 21 août 2022 où les partenaires hôteliers participants pourraient ne pas offrir de rabais, ou offrir un rabais inférieur à ceux stipulés dans cette offre.</p>
        <ul>
          <li class="regular-text">Le rabais de 20% est applicable sur les séjours de 2 nuits ou plus mais des rabais peuvent s’appliquer sur les séjours d’une nuit selon le partenaire hôtelier.</li>
          <li class="regular-text">Un minimum de 2 nuits est exigé pour les séjours incluant un samedi.</li>
          <li class="regular-text">Offre disponible chez les partenaires hôteliers participants.</li>
          <li class="regular-text">Sujet à disponibilité.</li>
        </ul>
        
        <h2 class="small-title">Achat et réservation</h2>
        <p class="regular-text">L’offre s’applique sur toute réservation effectuée entre le 1 er août à 13 h et le 3 août à 13 h, pour un séjour de de deux nuitées ou plus entre le 2 août et le 31 octobre 2022.</p>
        <ul>
          <li class="regular-text">Le séjour doit être réservé au moins 24 heures d’avance.</li>
        </ul>

        <h2 class="small-title">Remboursement</h2>
        <ul>
          <li class="regular-text">Prépayé: le montant total est requis au moment de la réservation et est non remboursable.</li>
        </ul>

        <h2 class="small-title">Restrictions</h2>
        <p class="regular-text">Du 18 au 21 août 2022, les partenaires hôteliers participants pourraient ne pas offrir de rabais, ou offrir un rabais inférieur à ceux stipulés dans cette offre.</p>
        <ul>
          <li class="regular-text">Un minimum de 2 nuits est exigé pour les séjours incluant un samedi.</li>
          <li class="regular-text">Cette offre ne peut être jumelée avec d’autres offres ou promotions sur l’hébergement.</li>
          <li class="regular-text">Si le séjour chevauche la période de début ou de fin de validité de l’offre, le rabais sera appliqué sur les nuitées à l’intérieur de la période de validité selon le rabais correspondant au nombre total de nuits du séjour.</li>
          <li class="regular-text">Ces rabais et dates sont modifiables sans préavis.</li>
        </ul>

      </div>

      <div v-else-if="$route.meta.lang === 'en'">

        <button @click.prevent="goback" class="cta prune">Back</button><br><br>

        <h1 class="big-title">Lodging<br>Terms & Conditions</h1>

        <h2 class="small-title">Validity</h2>
        <p class="regular-text">Offer is valid every day from August 2 to October 31, 2022, except from August 18 to 21, 2022, when participating hotels may offer lower discounts than the ones stipulated in this offer, or no discount at all.</p>
        <ul>
          <li class="regular-text">A 2-night stay minimum is required for stays including a Saturday.</li>
          <li class="regular-text">The 20% discount applies on two-night stay and more; some lodging partners may offer discounts on one-night stay.</li>
          <li class="regular-text">Offer available at participating hotels.</li>
          <li class="regular-text">Subject to availability.</li>
        </ul>
        
        <h2 class="small-title">Purchase and Booking</h2>
        <p class="regular-text">Offer applies to bookings made between August 1, 2022, 1 p.m., and August 3, 2022, 1 p.m., on one-night stays or more from August 2 to October 31, 2022.</p>
        <ul>
          <li class="regular-text">Bookings must be made at least 24 hours prior to arrival date.</li>
        </ul>

        <h2 class="small-title">Refund</h2>
        <ul>
          <li class="regular-text">Prepaid and non-refundable: the full amount must be paid upon booking and is non-refundable.</li>
        </ul>

        <h2 class="small-title">Restrictions</h2>
        <p class="regular-text">From August 18 to 21, 2022, when participating hotels may offer lower discounts than the ones stipulated in this offer, or no discount at all.</p>
        <ul>
          <li class="regular-text">A two-night stay minimum is required for stays including a Saturday.</li>
          <li class="regular-text">This offer cannot be combined to other lodging offers or promotions.</li>
          <li class="regular-text">If stay period starts or ends outside of the validity period, discount will be applied on nights included within the validity period only and its value will vary according to the number of nights included in the entire stay.</li>
          <li class="regular-text">These discounts and dates are subject to change without notice.</li>
        </ul>

      </div>
      
    </section>

    <div class="logo-partenaire">
      <a v-if="$route.meta.lang === 'en'" href="https://www.laurentides.com/en" target="_blank"><img src="@/assets/img/logo-tl-en.png" alt="Logo Tourisme Laurentides"></a>
    </div>

    <div class="overlay-form-loding ete_2022" :class="{ show: formlodging }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidelodgingform">
      <div class="form-wrap">
        <h3 class="medium-title">{{ $t('site.reservez-long') }}</h3>

        <form
          action="https://reservations.tremblant.ca/Ecomm/Shop/Lodging/2624850/fr-CA/?_ga=2.247307652.1090954148.1629141557-1179124804.1583853558&_gl=1*t5gc5p*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTIyOTEzOC4xNC4wLjE2MjkyMjkxMzguMA"
          class="lodging-form"
          target="_blank"
        >
          <div class="input">
            <p class="regular-text">{{ $t('site.arrivee') }}</p>
            <date-picker
              name="arrivaldate"
              v-model="time1"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'arrivaldate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.depart') }}</p>
            <date-picker
              name="departuredate"
              v-model="time2"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'departuredate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.adulte') }}</p>
            <input
              name="adultcount"
              type="number"
              value="2"
              min="1"
              required
            >
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.enfant') }}</p>
            <input
              name="childCount"
              type="number"
              value="0"
              required
            >
          </div>
          <input type="submit" class="cta submit blanc-rouge" :value="$t('site.reservez-long')">
        </form>
      </div>
      <div class="opacity" @click.prevent="hidelodgingform"></div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'

import * as LottiePlayer from '@lottiefiles/lottie-player'

import GLightbox from 'glightbox'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import trans from '@/translations/index'

import NewsLetter from '@/components/NewsLetter'
import FooterBar from '@/components/FooterBar'

export default {
  components: { NewsLetter, FooterBar, DatePicker },
  head() {
    return {
      title: {
        inner: this.$t(`site_ete_2022.launch_lodging_meta_title`),
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: this.$t(`site_ete_2022.launch_lodging_meta_title`) },
        {
          name: 'description',
          content: this.$t(`site_ete_2022.launch_lodging_meta_desc`),
          id: 'desc',
        },
        // Twitter OG
        { name: 'twitter:title', content: this.$t(`site_ete_2022.launch_lodging_meta_title`) },
        { name: 'twitter:card', content: 'Tremblant' },
        {
          n: 'twitter:description',
          c: this.$t(`site_ete_2022.launch_lodging_meta_desc`),
        },
        // Facebook / Open Graph
        { property: 'og:title', content: this.$t(`site_ete_2022.launch_lodging_meta_title`) },
        { property: 'og:site_name', content: 'Tremblant' },
        {
          property: 'og:description',
          content: this.$t(`site_ete_2022.launch_lodging_meta_desc`),
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://promo.tremblant.ca/vue-app-static-img/ete-2022/bighit/dink.jpg',
        },
        {
          name: 'twitter:image',
          content: 'https://promo.tremblant.ca/vue-app-static-img/ete-2022/bighit/dink.jpg',
        },
      ],
    }
  },
  props: {
    pageData: {
      type: Object,
      required: true,
      default: null
    },
  },
  data() {
    return {
      toggle: true,
      formlodging: false,
      sidepanel: false,
      lottieInstance: null,
      lightboxelement: null,
      datepickerLang: null,
      time1: new Date(),
      time2: null
    }
  },
  computed: mapState('app', ['appTitle']),
  methods: {
    goback() {
        return this.$router.go(-1)
    },
    table1() {
        this.toggle = true
    },
    table2() {
        this.toggle = false
    },
    showlodgingform() {
        if(this.formlodging) {
          this.formlodging = false
        } else {
          this.formlodging = true
        }
    },
    hidelodgingform() {
      this.formlodging = false
    },
    showsidepanel() {
        if(this.sidepanel) {
          this.sidepanel = false
        } else {
          this.sidepanel = true
        }
    },
    hidesidepanel() {
      this.sidepanel = false
    },
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    snowscroll() {
      const st = document.documentElement.scrollTop

      const element = this.$el.querySelector('.snow-background')
      const elementStyle = element.style

      element.style.top = `${ -150 + st /2}px`
    } 
  },
  mounted () {
    this.lottieInstance = LottiePlayer

    this.lightboxelement = GLightbox
    GLightbox()

    const dt = new Date()
    dt.setDate(dt.getDate() + 1)
    this.time2 = dt

    this.datepickerLang = trans.fr.datepicker
    if (this.$route.meta.lang === 'en') {
      this.datepickerLang = trans.en.datepicker
    }
  },
  created() {
      window.addEventListener('scroll', () => {
        this.snowscroll()
      })
    },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
